<template>
  <div class="iq-style4 order-container curva-list mb-5">
    <ul v-if="ordersList.length > 0" class="p-0">
      <li v-for="(order, key) in ordersList" :key="key" class="p-4 cursor-pointer"
          @click="$router.push({name:'orderDetails', params:{id: order.id, date: order.order_date}})"
          :class="key < ordersList.length-1 ? 'grey-border' : ''">
          <b-row>
            <b-col cols="3" lg="2">
              <div class="numberOfItems text-center">
                <h1 class="text-warning">{{ order.number_of_items }}</h1>
                <span>{{ $t('order.items') }}</span>
              </div>
            </b-col>
            <b-col cols="9" lg="10">
              <b-row class="d-flex flex-sm-row flex-column-reverse text-start">
                <b-col lg="5" class="pt-2"><span class="pr-2">{{ $t('order.no') }} </span>
                  <p>{{order.id}}</p></b-col>
                <b-col lg="5" class="pt-2"><span class="pr-2">{{ $t('order.order-date') }} </span>
                  <p>{{order.order_date}}</p></b-col>
                <b-col lg="12" class="pt-2" v-if="order.address"><span class="pr-2">{{ $t('order.address') }} </span>
                  <p>{{ order.address.street }}, {{order.address.city.name}}</p></b-col>
              </b-row>
              <span class="order-status-tag grey-tag">
                {{order.order_state}}</span>
            </b-col>
          </b-row>
      </li>
    </ul>
    <div v-else><h5 class="p-2 text-center text-white">{{ $t('profile.noOrders') }}</h5></div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  data () {
    return {
      ordersList: [],
      orderStatusTypes: [
        { label: this.$t('order.placed'), status: 'order placed', class: 'grey-tag' },
        { label: this.$t('order.shipment'), status: 'in shipment', class: 'blue-tag' },
        { label: this.$t('order.canceled'), status: 'canceled', class: 'red-tag' },
        { label: this.$t('order.delivered'), status: 'delivered', class: 'green-tag' },
        { label: this.$t('order.pending'), status: 'pending', class: 'blue-tag' },
        { label: this.$t('order.shipped'), status: 'shipped', class: 'yellow-tag' }
      ]
    }
  },
  methods: {
    getOrders () {
      ProfileServices.getOrders().then(res => {
        this.ordersList = res.data.data
      })
    }
  },
  created () {
    this.getOrders()
  },
  mounted () {
    core.index()
  }
}
</script>
